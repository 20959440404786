#projects{
 scroll-margin: 4rem;

}
.projects{
 overflow-y: auto;
 overflow-x: hidden;
 max-height: 600px;
 width: 100%;
 


}
.container{
 width: 100%;
}


.projects-gallery{
 width: 100%;
 display: grid;
 grid-template-columns: repeat(auto-fill, minmax(330px , 1fr));
 gap: 2rem;
 overflow: hidden;
 z-index: 999;
}

.port-box{
 position: relative;
 border-radius: 15px;
 overflow: hidden;
 background: #292e33;
 display: flex;
 grid-template-rows: 1fr auto;
 align-items: center;
 border: 2px solid var(--hover-color);
 box-shadow: var(--neon-box-shadow);
 max-width: 100%;
 margin-right: 1.5rem;
}


.port-image{
 display: flex;
 align-items: center;
 justify-content: center;
 height: 300px;
 width: 100%;
}

.port-image img{
 width: 100%;
 opacity: 0.5s;
 height: 100%;
 transition: .5s;
}

.port-content{
 position: absolute;
 bottom: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background: linear-gradient(rgba(0,0,0,.9), #ffb43a);
 display: flex;
 align-items: center;
 justify-content: center;
 flex-direction: column;
 text-align: center;
 transition: 0.5s;
 padding: 0 2rem;
 opacity: 0;
}

.port-box:hover .port-content{
 opacity: 1;
}

.port-box:hover .port-image img{
 transform: scale(1.1);
}

.port-content h3{
 font-size: 1.5rem;
 font-weight: 600;
 color: #000;
}
.port-content p{
  color: #000;
  font-size: .8rem;
  font-weight: 500;


}

.port-content a{
 background: #fff;
 border-radius: 50%;
 display: inline-flex;
 justify-content: center;
 align-items: center;
 width: 50px;
 height: 50px;
 color: #000;
 border: 1px solid var(--hover-color);
 outline: 2px solid #fff;
 margin-top: 10px;
}




/* category  */
.category-filter{
 width: 100%;
 display: flex;
 justify-content: center;
 gap: 10px;
 margin: 4rem 0;
 overflow-x: auto ;
}
.category-filter button {
  padding: 0.5rem 2.5rem;
  border-radius: 9999px;
  transition: all 0.2s ease;
  cursor: pointer;
}

.category-filter button.selected {
  background-color: #ffb43a;
  color: #000;
}

.category-filter button.unselected {
  background-color: #f1f5f9;
  color: #475569;
}

.category-filter button.unselected:hover {
  background-color: #e2e8f0;
}

.project_number{
  margin: 2rem 0;
  text-align: center;
  font-size: 1.2rem;
  line-height:2rem;
  letter-spacing: 1.5px 
  
}


@media screen and (max-width:280px) {
.projects-gallery{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
 gap: 20px;
}


}
@media screen and (min-width:667px) {
 .projects{
  margin: 0 1rem
 }
 

}

@media screen and (max-width:900px) {
  .category-filter{
  padding-left: 21rem;
}

}
